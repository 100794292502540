<template>
  <div class="select-color">
    <Loader v-if="isLoading" />
    <SmbColorPicker
      :colors-list="colorsList"
      :current-color="currentColor"
      :current-index="currentIndex"
      @colorListChanged="colorsList = $event"
      @currentIndexChanged="currentIndex = $event"
      @currentColorChanged="currentColor = $event"
    />
    <div class="footer-buttons">
      <SmbActionBtn
        class="arrow-button"
        isUseIcon
        iconName="mdi-arrow-left"
        width="20%"
        @click="$router.push({ name: 'profile-settings' })"
      />
      <SmbActionBtn
        width="80%"
        :isLoading="isLoadingSaveBtn"
        :emitName="isColorsChanged ? 'save' : 'cancel'"
        :text="isColorsChanged ? 'Save' : 'Cancel'"
        @save="saveColorsHandler"
        @cancel="$router.push({ name: 'profile-settings' })"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import SmbActionBtn from "@/components/smb-client/SmbActionBtn";
import Loader from "@/components/global/Loader.vue";
import SmbColorPicker from "@/components/smb-client/SmbColorPicker.vue";

export default {
  name: "SmbCustomerColorsSettings",
  data: () => ({
    currentColor: null,
    colorsList: [],
    currentIndex: null,
    prevColorVersion: [],
    isLoading: false,
    isLoadingSaveBtn: false,
  }),
  components: {
    SmbActionBtn,
    Loader,
    SmbColorPicker,
  },
  computed: {
    ...mapGetters("smbClientSelection", ["getCurrentSmbCustomer"]),
    isColorsChanged() {
      return (
        JSON.stringify(this.colorsList) !==
        JSON.stringify(this.prevColorVersion)
      );
    },
  },
  async created() {
    this.isLoading = true;
    if (!this.getCurrentSmbCustomer.brand_color_palettes.length) {
      await this.updateCustomer({
        customerId: this.getCurrentSmbCustomer.id,
        params: {
          brand_color_palettes: [["#000000", "#FFFFFF"]],
        },
      });
      await this.fetchSmbClientInfo(this.getCurrentSmbCustomer.id);
    }
    this.colorsList = this.getCurrentSmbCustomer.brand_color_palettes.length
      ? [...this.getCurrentSmbCustomer.brand_color_palettes[0]]
      : [];

    if (this.$route.params && this.$route.params.changeIndex) {
      this.currentIndex = this.$route.params.changeIndex;
      this.currentColor = this.colorsList[this.currentIndex];
    }

    this.prevColorVersion = [...this.colorsList];
    this.isLoading = false;
  },
  methods: {
    ...mapActions("smbClientSelection", ["fetchSmbClientInfo"]),
    ...mapActions("clientSelection", ["updateCustomer"]),
    async saveColorsHandler() {
      this.isLoadingSaveBtn = true;
      await this.updateCustomer({
        customerId: this.getCurrentSmbCustomer.id,
        params: {
          brand_color_palettes:
            this.getCurrentSmbCustomer.brand_color_palettes.with(
              0,
              this.colorsList
            ),
        },
      });
      await this.fetchSmbClientInfo(this.getCurrentSmbCustomer.id);
      this.prevColorVersion = [...this.colorsList];
      this.isLoadingSaveBtn = false;
      await this.$router.push({
        name:
          this.$route.params && this.$route.params.isFromBrandingPage
            ? "branding-settings"
            : "profile-settings",
        params: this.$route.params,
      });
    },
  },
};
</script>

<style scoped lang="scss">
.select-color {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: #f3f2f3;
}

.footer-buttons {
  display: flex;
  flex-direction: row;
  margin-top: auto;
  letter-spacing: 0;
  position: relative;
  @media screen and (min-width: 760px) {
    margin-top: 50px;
    max-width: 600px;
    padding: 0 20px 10px 20px;
    justify-content: center;
  }
  .button-item {
    width: 100%;
    @media screen and (min-width: 760px) {
      width: 313px;
    }
  }
  .arrow-button {
    width: 20%;
    position: absolute;
    left: 0;
    z-index: 10;
    @media screen and (min-width: 760px) {
      display: none;
    }
  }
}
</style>
